import request from 'utils/request';

const api = {
  DDJ_AFF: '/api/affiliate/ddj/aff',
  DDJ_OPERATION_LIST: '/api/affiliate/ddj/operation/list',
  DDJ_OPERATION_INSERT_OR_UPDATE: '/api/affiliate/ddj/operation/insertOrUpdate',
  DDJ_OPERATION_DELETE: '/api/affiliate/ddj/operation/delete',
  DDJ_OPERATION_EXEC_LOG: '/api/affiliate/ddj/execLog',
  DDJ_OPERATION_UN_EXEC_LOG: '/api/affiliate/ddj/unExecLog',
};

// 获取大点击渠道详情
export function getDDJAffApi() {
  return request({
    url: api.DDJ_AFF,
    method: 'get',
  });
}

export function getDDJOperationListApi(query) {
  return request({
    url: api.DDJ_OPERATION_LIST,
    method: 'get',
    params: query,
  });
}

export function updateDDJOperationApi(data) {
  return request({
    url: api.DDJ_OPERATION_INSERT_OR_UPDATE,
    method: 'post',
    data,
  });
}

export function deleteDDJOperationApi(query) {
  return request({
    url: api.DDJ_OPERATION_DELETE,
    method: 'delete',
    params: query,
  });
}

export function execLogDDJOperationApi(query) {
  return request({
    url: api.DDJ_OPERATION_EXEC_LOG,
    method: 'get',
    params: query,
  });
}

export function unExecLogDDJOperationApi(query) {
  return request({
    url: api.DDJ_OPERATION_UN_EXEC_LOG,
    method: 'get',
    params: query,
  });
}
