<template>
  <div class="affiliate_ddj">
    <div class="title" style="height: 54px;">
      <el-row :gutter="24">
        <el-col :span="16" style="margin-top:12px;">
          <h1 class="header">
            ID:
            <span>{{ ddjAffInfo.id }}</span>
            -
            <span>{{ ddjAffInfo.company }}</span>
            Ddj:
            <span>{{ ddjAffInfo.isDdj === 1 ? 'YES' : 'NO' }}</span>
          </h1>
        </el-col>
      </el-row>
    </div>
    <el-card>
      <el-tabs type="border-card" v-model="activeName" lazy>
        <el-tab-pane label="DDJ Execute Setting" name="first" style="margin-left:20px;">
          <DDJExecuteSetting :affiliateId="ddjAffInfo.id" />
        </el-tab-pane>
        <el-tab-pane label="DDJ Execute Log" name="second" lazy>
          <DDJExecuteLog :affiliateId="ddjAffInfo.id" />
        </el-tab-pane>
        <el-tab-pane label="DDJ UN Execute Log" name="thirdly" lazy>
          <DDJUnExecuteLog :affiliateId="ddjAffInfo.id" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
  import { getDDJAffApi } from 'api/affiliate/ddj';
  import DDJExecuteSetting from './components/DDJExecuteSetting';
  import DDJExecuteLog from './components/DDJExecuteLog';
  import DDJUnExecuteLog from './components/DDJUnExecuteLog';
  export default {
    components: {
      DDJExecuteSetting,
      DDJExecuteLog,
      DDJUnExecuteLog,
    },
    data() {
      return {
        activeName: 'first',
        ddjAffInfo: {
          id: -1,
          company: '',
          isDdj: 0,
        },
      };
    },
    methods: {
      getDDJAffInfo() {
        getDDJAffApi().then((response) => {
          if (response.code === 200) {
            console.log(response.result);
            this.ddjAffInfo = response.result;
          } else {
            this.$message.error('Error' + response.status.message);
          }
        });
      },
    },
    mounted() {
      this.getDDJAffInfo();
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .header {
    padding-left: 6px;
    @media screen and (min-width: 300px) {
      font-size: 14px;
    }

    @media screen and (min-width: 400px) {
      font-size: 16px;
    }

    @media screen and (min-width: 600px) {
      font-size: 18px;
    }

    @media screen and (min-width: 1000px) {
      font-size: 20px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 24px;
    }

    @media screen and (min-width: 1210px) {
      font-size: 26px;
    }
  }
  .account_item {
    width: 100%;
    height: 70px;
  }
  .button_group {
    padding-left: 10px;
  }
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
  .inline-input {
    padding: 2px;
  }
</style>
<style lang="scss">
  .addAuto {
    .el-dialog__body {
      padding: 30px 60px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
  }
  .msgBox {
    max-height: 100%;
    overflow: auto !important;
  }
  .el-table .gray-row {
    background-color: gray !important;
  }
</style>
