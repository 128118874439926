<template>
  <div>
    <div class="button_group">
      <el-form :inline="true" :model="filter">
        <el-form-item label="OfferId:" label-width="70px">
          <el-input v-model="filter.offerId" placeholder="offerId here"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getDDJOperationList(1)">Search</el-button>
          <el-button type="primary" @click="delOperations">Delete</el-button>
          <!-- addVisible= true -->
          <el-button type="primary" @click="addOperationClick">Add</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="operationList"
      border
      style="width:100%;"
      height="80vmin"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectionChange"
      :row-class-name="tableRowClassName"
      highlight-current-row
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="Affiliate Id" min-width="100" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="affiliatedetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Offer Id" min-width="100" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
            scope.row.offerId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="affSub"
        prop="affSub"
        min-width="200"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="AffSubClicks"
        prop="affSubClicks"
        min-width="200"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="OS" prop="platform" align="center"></el-table-column>
      <el-table-column label="Country" prop="country" align="center"></el-table-column>
      <el-table-column label="Clicks" prop="clicks" width="100" align="center"></el-table-column>
      <el-table-column
        label="BeginTime"
        prop="beginTime"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column label="EndTime" prop="endTime" width="120" align="center"></el-table-column>
      <el-table-column label="Hours" width="130" prop="hours" align="center"></el-table-column>
      <el-table-column label="Status" width="130" prop="status" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.status === 1 ? '开启' : '关闭' }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Operator"
        prop="createBy"
        width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="createTime"
        width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Option" align="center" min-width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="updateOperationClick(scope.row)" size="small"
            >Update</el-button
          >
          <el-button type="danger" @click="delRowClick(scope.row)" size="small">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleOperationSizeChange"
      @handleCurrentChange="handleOperationCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <el-dialog :title="`${operation.id ? 'Update' : 'Add'} Operation`" :visible.sync="addVisible">
      <el-form
        ref="addOperation"
        :model="operation"
        label-position="left"
        label-width="110px"
        :rules="rules"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="OfferId" prop="offerId">
              <el-input v-model="operation.offerId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Status" label-width="100px" prop="status">
              <el-select v-model="operation.status" placeholder="请选择" style="width:200px;">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Country" prop="country">
              <el-input v-model="operation.country"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Clicks" prop="clicks">
              <el-input v-model="operation.clicks"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Hours" prop="hours">
              <el-input v-model="operation.hours"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="BeginTime" label-width="100px" prop="beginTime">
              <el-time-select
                placeholder="Begin Time"
                v-model="operation.beginTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:06',
                  end: '23:59',
                }"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="OS" prop="platform">
              <el-select
                v-model="operation.platform"
                clearable
                placeholder="请选择"
                style="width:200px;"
              >
                <el-option
                  v-for="item in platformOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="AffSub" prop="affSub">
              <el-input v-model="operation.affSub"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="AffSubClicks" prop="affSub">
              <el-input v-model="operation.affSubClicks"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getDDJOperationListApi,
    updateDDJOperationApi,
    deleteDDJOperationApi,
  } from 'api/affiliate/ddj';

  import Pagination from '@/components/pagination';
  import { filterObject } from 'utils/utils';
  import { myMixin } from '@/mixins/mixins.js';

  const defaultOperation = {
    affiliateId: '',
    offerId: '',
    affSub: '',
    country: '',
    clicks: '',
    beginTime: '',
    hours: '',
    platform: 'Android',
    status: 1,
    affSubClicks: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    props: ['affiliateId'],
    data() {
      return {
        filter: {},
        offerWhiteSelectionList: [],
        operationList: [],
        total: 0,
        currentPage: 1,
        pageSize: 20,
        addVisible: false,
        operation: {},
        operationSelectedList: [],
        rules: {
          offerId: [
            {
              required: true,
              message: 'please write offerId',
              trigger: 'blur',
            },
          ],
          country: [
            {
              required: true,
              message: 'please write country',
              trigger: 'blur',
            },
          ],
          clicks: [
            {
              required: true,
              message: 'please write clicks',
              trigger: 'blur',
            },
          ],
          beginTime: [
            {
              required: true,
              message: 'please select beginTime',
              trigger: 'blur',
            },
          ],
          hours: [
            {
              required: true,
              message: 'please white hours',
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                const hour = Number(value);
                if (isNaN(hour)) {
                  callback(new Error('Hours must be numeric'));
                  return;
                } else if (value < 0 || value > 24) {
                  callback(new Error('The hours range must be 0~24'));
                  return;
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
          platform: [
            {
              required: true,
              message: 'please select platform',
              trigger: 'blur',
            },
          ],
          status: [
            {
              required: true,
              message: 'please select status',
              trigger: 'blur',
            },
          ],
        },
        platformOptions: [
          {
            label: 'IOS',
            value: 'IOS',
          },
          {
            label: 'Android',
            value: 'Android',
          },
        ],
        statusOptions: [
          {
            label: '关闭',
            value: 0,
          },
          {
            label: '开启',
            value: 1,
          },
        ],
      };
    },
    methods: {
      getDDJOperationList(page) {
        if (page) {
          this.currentPage = page;
        }
        const param = filterObject({
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
          affiliateId: this.affiliateId,
        });
        getDDJOperationListApi(param).then((response) => {
          if (response.code === 200) {
            this.operationList = response.result;
            this.total = response.total;
            this.pageSize = response.pageSize;
            this.currentPage = response.currentPage;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      addOperationClick() {
        this.operation = Object.assign({}, defaultOperation);
        this.addVisible = true;
      },
      updateOperationClick(row) {
        this.operation = row;
        this.addVisible = true;
      },
      handleOfferWhiteSelectionChange(val) {
        this.offerWhiteSelectionList = val.map((item) => item.offerId);
      },
      tableRowClassName(row) {
        if (row.row.offerStatus == 'hold') {
          return 'gray-row';
        } else {
          return '';
        }
      },
      submitForm() {
        this.$refs.addOperation.validate((valid) => {
          if (valid) {
            const param = {
              ...this.operation,
              affiliateId: this.affiliateId,
            };
            const [hour, min] = param.beginTime.split(':');
            const endHour = (Number(hour) + Number(param.hours)) % 24;
            param.endTime = `${endHour < 10 ? '0' + endHour : endHour}:${min}`;
            updateDDJOperationApi(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: `${param.id ? 'Update' : 'Add'} success`,
                  type: 'success',
                });
                this.getDDJOperationList();
                this.addVisible = false;
              } else {
                this.$message.error('Add Error:' + response.message);
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleOperationSizeChange(val) {
        this.pageSize = val;
        this.handleOperationCurrentChange(1);
      },
      handleOperationCurrentChange(val) {
        this.currentPage = val;
        this.getDDJOperationList();
      },
      delRowClick(row) {
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            const id = row.id;
            this.deleteDDJOperation(id);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      delOperations() {
        const length = this.operationSelectedList.length;
        if (length <= 0) {
          this.$message.warning('请先选择要删除的数据~');
          return;
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            const ids = this.operationSelectedList.map((item) => item.id)?.join();
            this.deleteDDJOperation(ids);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      deleteDDJOperation(operationIds) {
        deleteDDJOperationApi({
          affiliateId: this.affiliateId,
          operationIds,
        }).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getDDJOperationList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.operationSelectedList = val;
      },
      affiliatedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliateId,
          },
        });
        window.open(href, '_blank');
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
    },
  };
</script>

<style lang="scss">
  .el-table .gray-row {
    background-color: gray !important;
  }
  .err-tip .el-message-box__message {
    color: red;
    font-weight: bolder;
  }
</style>
