<template>
  <div class="logAdv">
    <div>
      <el-form v-model="logAdvs" :inline="true">
        <el-row :gutter="24">
          <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
            <label class="labelName">Offer(s)</label>
          </el-col>
          <el-form-item>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-input
                v-model="logAdvs.offerIds"
                placeholder="offerIds: eg. 2206264 or 2206264,1567396"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
              <el-checkbox v-model="offerChecked"></el-checkbox>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row :gutter="24">
          <el-col :xs="24" :sm="2" :md="2" :lg="3" :xl="2">
            <label class="labelName">Countries(s)</label>
          </el-col>
          <el-form-item label="">
            <el-col :lg="6" :md="20" :xs="24" :sm="12" :xl="12">
              <el-input
                v-model="logAdvs.country"
                style="width:2.4rem;"
                placeholder="eg. US,IT"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
              <el-checkbox v-model="countriesChecked"></el-checkbox>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row>
          <label class="labelName" style="margin-right:20px;">TimeZone</label>
          <el-form-item>
            <el-select v-model="logAdvs.timezone" style="width:420px;">
              <el-option
                v-for="item in timezoneOption"
                :key="item.value"
                :value="item.number"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :lg="2" :md="2" :xs="3" :sm="3" :xl="2">
            <label class="labelName">Interval</label>
          </el-col>
          <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
            <el-form-item label="Month">
              <el-checkbox v-model="monthChecked"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
            <el-form-item label="Date">
              <el-checkbox v-model="dateChecked"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
            <el-form-item label="Hour">
              <el-checkbox v-model="hourChecked"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <label class="labelName" style="margin-right:60px;">Date</label>
          <el-form-item>
            <el-date-picker
              v-model="startToEndDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              style="width:260px;"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :lg="3" :md="20" :offset="1">
            <el-form-item>
              <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="dataList"
          highlight-current-row
        >
          <el-table-column label="Date" prop="date" align="center" min-width="110">
          </el-table-column>
          <el-table-column
            label="AffiliateId"
            prop="affiliateId"
            align="center"
            v-if="affiliateChecked"
          ></el-table-column>
          <el-table-column
            label="OfferId"
            prop="offerId"
            align="center"
            v-if="offerChecked"
          ></el-table-column>
          <el-table-column label="Click Setting" prop="clicksSetting" min-width="85" align="center">
          </el-table-column>
          <el-table-column label="Click Execting" prop="clicksExec" min-width="85" align="center">
          </el-table-column>
          <el-table-column
            label="Country"
            prop="country"
            align="center"
            min-width="110"
            v-if="countriesChecked"
          >
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import { execLogDDJOperationApi } from 'api/affiliate/ddj';
  import Pagination from '@/components/pagination';
  import { filterObject } from 'utils/utils';
  import { cloneDeep } from 'lodash-es';

  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import dateUtils from '@/utils/dateutils.js';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    props: ['affiliateId'],
    computed: {
      listenChange() {
        const { affiliateChecked, offerChecked, countriesChecked } = this;
        return {
          affiliateChecked,
          offerChecked,
          countriesChecked,
        };
      },
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        startToEndDate: '',
        selectLoading: false,
        listLoading: false,
        dataList: [],
        total: null,
        logAdvs: {
          country: '',
          offerId: '',
          columns: [],
        },
        timezoneOption: optionData.timezoneOption,
        dateChecked: false,
        hourChecked: true,
        monthChecked: false,
        affiliateChecked: true,
        offerChecked: true,
        countriesChecked: false,
      };
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      searchListClick(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          ...this.logAdvs,
          affiliateId: this.affiliateId,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.offerChecked == true && param.columns.indexOf('offer_id') == -1) {
          param.columns.push('offer_id');
        } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = param.columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          param.columns.splice(offerIdIndex, 1);
        }
        if (this.affiliateChecked == true && param.columns.indexOf('affiliate_id') == -1) {
          param.columns.push('affiliate_id');
        } else if (this.affiliateChecked == false && param.columns.indexOf('affiliate_id') !== -1) {
          const affiliateIndex = param.columns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          param.columns.splice(affiliateIndex, 1);
        }
        if (this.countriesChecked == true && param.columns.indexOf('country') == -1) {
          param.columns.push('country');
        } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
          const countryIndex = param.columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          param.columns.splice(countryIndex, 1);
        }

        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
          param.columns.push('date');
        } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.columns)) {
          dataParam.columns = dataParam.columns.join(',');
        }
        this.dataParam = dataParam;
        this.getDataList();
      },
      getDataList() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
        };
        param = filterObject(param);
        execLogDDJOperationApi(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            if (response.result.length == 0) {
              this.$message({
                message: '数据列表为空',
                type: 'warning',
              });
            }

            const summariesMap = response.result.pop() || [];
            const result = response.result || [];
            if (result.length <= 0) {
              return;
            }
            this.dataList = result;
            this.total = response.total;
            let totalObject = new Object();
            totalObject.date = 'Total';
            totalObject.clicksSetting = summariesMap.clicksSetting;
            totalObject.clicksExec = summariesMap.clicksExec;
            this.dataList.push(totalObject);
          } else {
            this.dataList = [];
            this.listLoading = false;
            this.$message.error('Error: ' + response.message);
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
