<template>
  <div class="logAdv">
    <el-button type="primary" @click="getDataList(1)" style="margin-bottom:10px;">
      In Browser
    </el-button>
    <div>
      <div>
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="dataList"
          highlight-current-row
        >
          <el-table-column label="Click Setting" prop="clicksSetting" min-width="85" align="center">
          </el-table-column>
          <el-table-column label="Click Execting" prop="clicksExec" min-width="85" align="center">
          </el-table-column>
          <el-table-column label="Country" prop="country" align="center" min-width="110">
          </el-table-column>
          <el-table-column label="Date" prop="execTm" align="center" min-width="110">
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination
      ></div>
    </div>
  </div>
</template>
<script>
  import { unExecLogDDJOperationApi } from 'api/affiliate/ddj';
  import Pagination from '@/components/pagination';

  import { myMixin } from '@/mixins/mixins.js';
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        startToEndDate: '',
        selectLoading: false,
        listLoading: false,
        dataList: [],
        total: 0,
      };
    },
    methods: {
      getDataList(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };

        unExecLogDDJOperationApi(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.dataList = response.result;
            this.total = response.total;
            this.pageSize = response.pageSize;
            this.currentPage = response.currentPage;
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.getDataList();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
